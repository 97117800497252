<script>
import {
  StatusBusinessPlanEnum,
  StatusBusinessPlanLabels
} from "@/core/enums/ativacaoped";
import { cloneDeep, isEqual } from "lodash";

export default {
  props: {
    disabledDecrease: Boolean,
    disabledIncrease: Boolean,
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      innerValue: cloneDeep(this.value),
      isValid: true,
      originalValue: cloneDeep(this.value)
    };
  },
  computed: {
    aprovadores() {
      return [
        {
          value: "FP&A Canal"
        },
        {
          value: "FP&A Produto"
        },
        {
          value: "CFO"
        },
        {
          value: "N3 Tecnologia"
        },
        {
          value: "N3 Produto"
        }
      ];
    },
    disabledSave() {
      return !this.isValid || !this.hasChanges;
    },
    isEdit() {
      return !!this.innerValue.id;
    },
    hasChanges() {
      // Verifica se a alterações no formulário ignorando o campo ORDEM
      const current = cloneDeep(this.innerValue);
      const original = cloneDeep(this.originalValue);
      delete current.ordem;
      delete original.ordem;
      return !isEqual(current, original);
    },
    showDelete() {
      return this.isEdit;
    },
    showSave() {
      return !this.isEdit || this.hasChanges;
    },
    status() {
      return [
        {
          id: StatusBusinessPlanEnum.DEFINICAO,
          value: StatusBusinessPlanLabels.DEFINICAO
        },
        {
          id: StatusBusinessPlanEnum.APROVACAO,
          value: StatusBusinessPlanLabels.APROVACAO
        },
        {
          id: StatusBusinessPlanEnum.ANDAMENTO,
          value: StatusBusinessPlanLabels.ANDAMENTO
        },
        {
          id: StatusBusinessPlanEnum.PRE_AMORTIZACAO,
          value: StatusBusinessPlanLabels.PRE_AMORTIZACAO
        },
        {
          id: StatusBusinessPlanEnum.AMORTIZACAO,
          value: StatusBusinessPlanLabels.AMORTIZACAO
        }
      ];
    }
  },
  // created() {},
  methods: {
    decreaseOrder() {
      this.$emit("decreaseOrder");
    },
    onDelete() {
      this.$emit("delete", this.innerValue.id);
    },
    increaseOrder() {
      this.$emit("increaseOrder");
    },
    required(type) {
      if (type === "autocomplete") {
        return value =>
          (Array.isArray(value) && value.length > 0) || "Obrigatório.";
      }

      return value => !!value || "Obrigatório.";
    },
    resetValue(value) {
      if (value) {
        this.originalValue = cloneDeep(value);
        return;
      }

      this.originalValue = cloneDeep(this.innerValue);
    },
    onSave() {
      this.isValid = this.$refs.form.validate();

      if (!this.isValid) {
        return;
      }

      this.$emit("save", cloneDeep(this.innerValue));
    }
  },
  watch: {
    innerValue: {
      deep: true,
      handler(next) {
        this.$emit("update:value", next);
      }
    },
    value: {
      deep: true,
      handler(next) {
        const current = cloneDeep(this.innerValue);
        const _next = cloneDeep(next);
      
        if (!isEqual(current, _next)) {
          this.innerValue = { ...next };
        }
      }
    }
  }
};
</script>

<template>
  <v-card elevation="4">
    <v-form ref="form" lazy-validation>
      <div class="grab-container">
        <template v-if="isEdit">
          <v-icon>mdi-drag-horizontal</v-icon>
        </template>
      </div>

      <div class="form">
        <div class="field field-order">
          <template v-if="isEdit">
            <v-btn :disabled="disabledIncrease" icon @click="decreaseOrder">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
            <span>
              {{ innerValue.ordem || ' — ' }}
            </span>
            <v-btn :disabled="disabledDecrease" icon @click="increaseOrder">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
        </div>
        <div class="field">
          <span>Nome do fluxo</span>
          <v-text-field
            v-model="innerValue.nome"
            dense
            outlined
            :rules="[required('text-field')]"
          />
        </div>
        <div class="field">
          <span>Status</span>
          <v-autocomplete
            v-model="innerValue.status"
            dense
            outlined
            :items="status"
            item-value="id"
            item-text="value"
            :rules="[required('text-field')]"
          />
        </div>
        <div class="field">
          <span>Aprovadores</span>
          <v-autocomplete
            v-model="innerValue.aprovadores"
            dense
            outlined
            :items="aprovadores"
            item-value="value"
            item-text="value"
            multiple
            :rules="[required('autocomplete')]"
          />
        </div>
        <div class="field">
          <span>SLA</span>
          <v-text-field
            v-model="innerValue.sla"
            dense
            outlined
            :rules="[required('text-field')]"
            type="number"
          />
        </div>
      </div>

      <div class="action-button-container">
        <v-btn
          v-if="showSave"
          :color="tableColor"
          depressed
          :disabled="disabledSave"
          @click="onSave"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>

        <v-btn v-if="showDelete" color="#ef4848" depressed @click="onDelete">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<style lang="scss" scoped>
.action-button-container,
.grab-container,
.form {
  display: flex;
}

.action-button-container {
  flex-direction: column;
  height: 100%;
  width: 3rem;

  &:hover {
    cursor: pointer;
  }

  .v-btn {
    border-radius: 0;
    flex: 1;
    min-width: unset;

    &:first-child {
      border-top-left-radius: 0.25rem !important;
    }

    &:last-child {
      border-bottom-left-radius: 0.25rem !important;
    }

    &.v-btn--disabled {
      background-color: unset !important;
      opacity: 0.5;
    }

    .v-icon.v-icon {
      color: #fff;
    }
  }
}

.grab-container {
  cursor: grab;
  justify-content: center;
  width: 3rem;

  &:active {
    cursor: grabbing;
  }

  .v-icon.v-icon {
    width: min-content;
  }
}

.form {
  width: 100%;

  .field {
    flex: 1;
    padding: 1rem;
    padding-bottom: 0;

    &:first-child {
      flex: 0.125;
    }

    &:last-child {
      flex: 0.5;
    }

    &.field-order {
      align-items: center;
      display: flex;
      flex-direction: column;
      font-weight: bold;
      justify-content: center;
      padding: 0;

      > span {
        text-align: center;
      }
    }
  }
}

.v-form {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 7.75rem;
}
</style>
