import { v4 as uuid } from "uuid";
import { isNil } from "lodash";

/**
 *
 * @param {string} resourceName
 * @param {object?} options
 * @param {function} options.afterDelete (data: T) => T
 * @param {function} options.afterEnd () => void
 * @param {function} options.afterUpdate (data: T) => T
 * @param {function} options.beforeStart () => void
 * @param {number} options.delay
 * @param {array} options.initialValue
 * @param {boolean} options.serverPagination
 * @param {function | array} options.validateDelete (body: any) => null || string[]
 * @param {function | array} options.validateUpdate (body: any) => null || string[]
 * @returns
 */
export const fakeResource = (
  resourceName,
  {
    afterDelete,
    afterEnd,
    afterUpdate,
    beforeStart,
    delay,
    initialValue,
    serverPagination,
    validateDelete,
    validateUpdate
  } = {}
) => {
  const storageKey = `fakedb-${resourceName}`;
  const timeout = delay > 0 ? delay : 750;

  const getFakeData = () => {
    const unparsedData = window.localStorage.getItem(storageKey) ?? "[]";
    return JSON.parse(unparsedData);
  };

  const saveFakeData = data => {
    const stringifiedData = JSON.stringify(data);
    window.localStorage.setItem(storageKey, stringifiedData);
  };

  if (initialValue) {
    saveFakeData(initialValue);
  }

  return {
    // TODO implementar lidar com query
    get(id) {
      return new Promise((resolve, reject) => {
        beforeStart && beforeStart();
        setTimeout(() => {
          const data = getFakeData() ?? [];

          if (!isNil(id)) {
            const found = data.find(parametro => parametro.id === id);
            found ? resolve(found) : reject({ error: "Não encontrado." });
            afterEnd && afterEnd();
            return;
          }

          // TODO implementar respeitar a query de paginação
          if (serverPagination) {
            resolve({
              data,
              links: {},
              meta: {
                current_page: 1,
                from: 1,
                last_page: 1,
                path: "",
                per_page: `${1}`,
                to: 10,
                total: 10
              }
            });
            afterEnd && afterEnd();
            return;
          }

          resolve(data);
          afterEnd && afterEnd();
          return;
        }, timeout);
      });
    },
    save(body, id) {
      beforeStart && beforeStart();
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (validateUpdate) {
            const errors = validateUpdate();

            if (Array.isArray(errors)) {
              reject({ errors });
              afterEnd && afterEnd();
              return;
            }
          }

          let response = { ...body };
          let data = getFakeData() ?? [];

          if (!isNil(id)) {
            const index = data.findIndex(d => d.id === id);

            if (index === -1) {
              reject({ error: "Não encontrado." });
              afterEnd && afterEnd();
              return;
            }

            const found = data[index];
            response = { ...found, ...body };
            data[index] = response;
          } else {
            response = { ...body, id: uuid() };
            data.push(response);
          }

          if (afterUpdate) {
            data = afterUpdate(data);
          }

          saveFakeData(data);
          resolve(response);
          afterEnd && afterEnd();
          return;
        }, timeout);
      });
    },
    delete(id) {
      beforeStart && beforeStart();
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (isNil(id)) {
            reject({ error: "Id inválido" });
            afterEnd && afterEnd();
            return;
          }

          let data = getFakeData() ?? [];
          const index = data.findIndex(d => d.id === id);

          if (index === -1) {
            reject({ error: "Não encontrado." });
            afterEnd && afterEnd();
            return;
          }

          const [found] = data.splice(index, 1);

          if (validateDelete) {
            const errors = validateDelete(found);

            if (Array.isArray(errors)) {
              reject({ errors });
              afterEnd && afterEnd();
              return;
            }
          }

          if (afterDelete) {
            data = afterDelete(data);
          }

          saveFakeData(data);
          resolve({ message: "Deletado" });
          afterEnd && afterEnd();
          return;
        }, timeout);
      });
    }
  };
};

export default fakeResource;
