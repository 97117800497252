<script>
import ParameterCard from "./components/parameter-card.vue";
import fakeResource from "@/helpers/fakeResource";
import Draggable from "vuedraggable";
import { toNumber } from "lodash";

const EMPTY_PARAMETER = {
  aprovadores: [],
  id: null,
  nome: "",
  ordem: null,
  sla: "",
  status: ""
};

export default {
  components: { Draggable, ParameterCard },
  data() {
    return {
      parameters: [
        {
          ...EMPTY_PARAMETER
        }
      ]
    };
  },
  computed: {
    resource() {
      // TODO integração com API
      const sortAndFillGaps = (array) => {
        const sortedArray = array
          .filter(item => item.ordem !== null)
          .sort(({ ordem: a }, { ordem: b }) => a - b);

        for (let i = 0; i < sortedArray.length; i++) {
          sortedArray[i].ordem = i + 1;
        }

        const nullItems = array.filter(item => item.ordem === null);
        let lastParamValue = sortedArray.length > 0 ? sortedArray[sortedArray.length - 1].ordem : 0;

        for (const item of nullItems) {
          item.ordem = lastParamValue + 1;
          lastParamValue++;
        }

        return [...sortedArray, ...nullItems];
      }
      const afterEnd = () => this.$root.$emit("removeLoadingProcess", "ativacaoped-parametrizacao");
      const beforeStart = () => this.$root.$emit("addLoadingProcess", "ativacaoped-parametrizacao");
      return fakeResource("ativacaoped-parametrizacao", {
        afterDelete: sortAndFillGaps,
        afterEnd,
        afterUpdate: sortAndFillGaps,
        beforeStart,
      });
    }
  },
  created() {
    this.doLoad();
  },
  methods: {
    async doDelete(id, index) {
      try {
        await this.resource.delete(id);
        this.$notify({
          title: "Sucesso!",
          text: `Parametro excluído com sucesso.`,
          type: "success",
          group: "geral"
        });
        this.parameters.splice(index, 1);
      } catch (error) {
        this.notify(error);
      }
    },
    async doLoad() {
      try {
        this.parameters = [];
        const response = await this.resource.get();
        this.parameters = [
          ...response.sort(({ ordem: a }, { ordem: b }) => a - b),
          {
            ...EMPTY_PARAMETER
          }
        ];
      } catch (error) {
        this.notify(error);
        this.parameters = [
          {
            ...EMPTY_PARAMETER
          }
        ];
      }
    },
    async doReorder(id, prevIndex, nextIndex) {
      try {
        await this.resource.save({ ordem: nextIndex }, id);
        
        const [target] = this.parameters.splice(prevIndex, 1);
        this.parameters.splice(nextIndex, 0, target);

        for (let i = 0; i < this.parameters.length - 1; i++) {
          this.parameters[i].ordem = i + 1;
        }
      } catch (error) {
        this.notify(error);
      }
    },
    async doSave(data, index) {
      try {
        const body = { ...data, sla: toNumber(data.sla || 0) };
        const next = await this.resource.save(body, data.id);
        this.$notify({
          title: "Sucesso!",
          text: `Parametro ${data.id ? "alterado" : "criado"} com sucesso.`,
          type: "success",
          group: "geral"
        });

        this.parameters.splice(index, 1, next);
        this.$refs.parameters[index].resetValue(next);

        if (!data.id) {
          // Preciso criar o novo card vazio para cadastro no próximo ciclo de renderização
          // para que ele seja de fato criado e não reuse o elemento previamente criado na tela
          // que vai herdar o estado de validação dos campos anteriores
          setTimeout(() => {
            this.parameters.push({
              ...EMPTY_PARAMETER
            });
          }, 200);
        }
      } catch (error) {
        this.notify(error);
      }
    },
    async onEnd({ oldIndex, newIndex }) {
      try {
        const { id } = this.parameters[newIndex];
        await this.resource.save({ ordem: newIndex }, id);

        for (let i = 0; i < this.parameters.length - 1; i++) {
          this.parameters[i].ordem = i + 1;
        }
      } catch (error) {
        this.notify(error);
        
        const [target] = this.parameters.splice(oldIndex, 1);
        this.parameters.splice(newIndex, 0, target);
      }
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return !!relatedElement?.id && !!draggedElement.id;
    }
  }
};
</script>

<template>
  <v-card :color="tableColor" elevation="0">
    <v-card-text class="d-flex">
      <div
        aria-label="refreshButton"
        class="table-v-action-button mr-2"
        @click="doLoad"
      >
        <v-icon>mdi-refresh</v-icon>
        Atualizar
      </div>
    </v-card-text>

    <Draggable
      :list.sync="parameters"
      class="cards-container"
      handle=".grab-container"
      :move="onMove"
      @end="onEnd"
    >
      <ParameterCard
        v-for="(param, i) in parameters"
        ref="parameters"
        class="parameter-card"
        :disabledDecrease="i === parameters.length - 2"
        :disabledIncrease="i === 0"
        :key="param.id || 'new'"
        :value="param"
        @decreaseOrder="doReorder(param.id, i, i - 1)"
        @delete="doDelete($event, i)"
        @increaseOrder="doReorder(param.id, i, i + 1)"
        @save="doSave($event, i)"
      />
    </Draggable>
  </v-card>
</template>

<style lang="scss" scoped>
.table-v-action-button {
  user-select: none;
}

.cards-container {
  background-color: #fff;
  height: calc(100vh - 12rem);
  overflow-y: auto;
  padding: 1rem 2.75rem;

  .parameter-card {
    margin-bottom: 1rem;
  }
}
</style>
